'use client';

import BDButton from 'components/elements/button';

export default function Error({ reset }: { reset: () => void }) {
  return (
    <div className="mx-auto my-4 flex max-w-xl flex-col rounded-lg border border-neutral-200 bg-white p-8 md:p-12">
      <h2 className="text-xl font-bold">Oh no!</h2>
      <p className="my-2">
        There was an issue with our Bonded Diamonds storefront. This could be a temporary issue,
        please try your action again.
      </p>
      <BDButton styleType="primary" onClick={() => reset()}>
        Try Again
      </BDButton>
    </div>
  );
}
