'use client';
import { Button } from '@headlessui/react';
import clsx from 'clsx';
import { Spinner } from 'components/layout/spinner';
import { useRouter } from 'next/navigation';
import React, { ReactNode } from 'react';

type BDButtonProps = {
  styleType: 'primary' | 'secondary' | 'tertiary' | 'base' | 'menu';
  theme?: 'light' | 'dark';
  href?: string;
  disabledMessage?: ReactNode;
  pending?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;
const base =
  'group cursor-pointer font-button disabled:cursor-not-allowed disabled:opacity-70 transition duration-300 ease-in-out focus:outline-hidden focus:ring-2 focus:ring-offset-2 relative rounded-md';
const primary =
  'bg-bd-primary uppercase text-white hover:bg-bd-primary/90 hover:shadow-sm hover:shadow-bd-primary/80 focus:ring-bd-primary/80 ';
const secondary =
  'bg-transparent text-bd-primary uppercase border border-bd-primary hover:border-bd-primary/80 hover:shadow-sm hover:shadow-bd-primary/80 focus:ring-bd-primary/80';
const tertiary =
  'capitalize border border-bd-contrast bg-transparent text-bd-contrast hover:underline font-normal';
const tertiaryDark = 'capitalize bg-transparent hover:underline focus:ring-bd-primary font-normal';
const menu =
  'font-menu font-normal items-center text-xs uppercase hover:text-bd-primary hover:border-b border-bd-primary hover:font-medium ring-bd-primary ring-0';

const BDButton: React.FC<BDButtonProps> = ({
  styleType,
  theme = 'light',
  href,
  disabledMessage,
  children,
  className,
  pending,
  ...rest
}) => {
  let styleClass;
  switch (styleType) {
    case 'menu':
      styleClass = menu;
      break;
    case 'primary':
      styleClass = primary;
      break;
    case 'secondary':
      styleClass = secondary;
      break;
    case 'tertiary':
      styleClass = theme === 'light' ? tertiary : tertiaryDark;
      break;
    default:
      styleClass = '';
  }
  const combinedClassName = clsx(base, styleClass, className);

  const router = useRouter();
  return (
    <button
      className={clsx(combinedClassName, pending && 'opacity-50')}
      onClick={() => {
        if (href) {
          router.push(href);
        }
      }}
      role={href ? 'link' : 'button'}
      {...rest}
    >
      {children}
      {pending && <Spinner className="h-5 w-5" />}

      {disabledMessage && !pending && (
        <p className="absolute top-full right-0 mt-1 hidden text-xs font-light whitespace-nowrap text-red-500 normal-case group-hover:group-disabled:block">
          {disabledMessage}
        </p>
      )}
    </button>
  );
};

export default BDButton;

interface SelectionButtonProps {
  value: any;
  selectedValue: any;
  label: string;
  setSelectedValue: (_value: SelectionButtonProps['value']) => void;
  children: ReactNode;
}

const SelectionButton: React.FC<SelectionButtonProps> = ({
  value,
  selectedValue,
  label,
  setSelectedValue,
  children
}) => {
  const selected = (() => {
    if (typeof value === 'object' && typeof selectedValue === 'object') {
      return value?.id === selectedValue?.id;
    }
    if (typeof value === 'number' && typeof selectedValue === 'number') {
      return value === selectedValue;
    }
    return false;
  })();

  return (
    <Button
      className={clsx(
        'group flex h-full w-full cursor-pointer items-center rounded-md border transition duration-300 hover:border-slate-800 hover:shadow-md disabled:cursor-default',
        selected ? 'border-2 border-slate-800' : 'm-[1px] border-slate-300'
      )}
      aria-label={label}
      onClick={() => setSelectedValue(value)}
    >
      <div className="h-full w-full p-4 group-disabled:opacity-70">{children}</div>
    </Button>
  );
};

export { SelectionButton };
