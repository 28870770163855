import clsx from 'clsx';

export default function FullPageSpinner({ className }: { className?: string }) {
  return (
    <div className={clsx('flex h-full w-full items-center justify-center', className)}>
      <div className="border-bd-secondary border-t-bd-primary h-12 w-12 animate-spin rounded-full border-4" />
    </div>
  );
}

export function Spinner({ className }: { className?: string }) {
  return (
    <>
      <div className="bg-bd-secondary/50 absolute inset-0 z-10 transition-opacity" />
      <div className="absolute inset-0 top-1/2 left-1/2 z-10 flex h-full w-full -translate-x-1/2 -translate-y-1/2 items-center justify-center">
        <div
          className={clsx(
            'border-bd-secondary border-t-bd-primary animate-spin rounded-full border-4',
            className
          )}
        />
      </div>
    </>
  );
}
